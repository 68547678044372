import { Card, CardContent, Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { capitaliseFirstLetter, deduplicateArrayToString } from '../../settings/utils';
import { colors } from '../../settings/colors';
import { CoccaAccordion, CoccaAccordionDetails, CoccaAccordionSummary, CoccaCentredAccordionSummary, AccordionWrapper } from './Accordions';
import { deduplicateArray } from '../../settings/utils';
import Entry from './Entry';

const Remarks = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Box className='rdap-entities-remarks'>
      <Typography variant='h6'>Remarks</Typography>
      {data?.map((remark, index) => (
        <Card key={`${remark.title}-remark-${index}`} variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <Typography component="div">
              {remark.title}
            </Typography>
            <Typography sx={{ mb: 1.5 }}>
              {remark.type}
            </Typography>
            <Typography>
              {remark.description.join(' ')}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const VCards = ({ data }) => {
  if (!data) {
    return null;
  }

  const vcards = data[1];

  return (
    <Box className='rdap-entities-vcards'>
      <Typography variant='h6'>VCards</Typography>

      {vcards.map((vcard) => {
        const label = `${capitaliseFirstLetter(vcard[0])}: `;
        const valueRaw = vcard[3];
        let value = typeof valueRaw === 'string'
          ? valueRaw
          : deduplicateArrayToString(valueRaw);
        value = value.trim();
        if (value.startsWith('tel:') || value.startsWith('fax:')) {
          value = value.substring(4);
        }
          return <Entry key={label} label={label} value={value} />;
      })}
    </Box>
  );
};

const PublicIds = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Box className='rdap-entities-public-ids'>
      <Typography variant='h6'>Public IDs</Typography>

      {data.map((publicId, index) => (
        <Entry 
          key={`publicid-${publicId.type}-${publicId.identifier}-${index}`} 
          label={`Public ID #${++index}:`} 
          value={`Type: ${publicId.type}, identifier: ${publicId.identifier}`} 
        />
      ))}
    </Box>
  );
};

const NestedEntities = ({ data }) => {
  const [expanded, setExpanded] = React.useState(data?.map(d => true));

  const handleChange = (panel) => (event, isExpanded) => {
    const newExpanded = [...expanded];

    newExpanded[panel] = isExpanded ? true : false;

    setExpanded(newExpanded);
  };

  if (!data) {
    return null;
  }

  return (
    <Card sx={{ mt: 2 }}>
      <CoccaAccordion defaultExpanded={true}>
        <CoccaCentredAccordionSummary sx={{ backgroundColor: colors.lightGrey }}>
          <Typography>Additional Information</Typography>
        </CoccaCentredAccordionSummary>
        <CoccaAccordionDetails>
          {data.map((entity, index) => (
            <CoccaAccordion expanded={expanded[index]} onChange={handleChange(index)} key={`inner-accordion-${entity.handle}-${index}`}>
              <CoccaAccordionSummary sx={{ backgroundColor: colors.lightGrey }}>
                <Typography>{entity.handle} ({deduplicateArrayToString(entity.roles)})</Typography>
              </CoccaAccordionSummary>
              <CoccaAccordionDetails>
                <Stack>
                  <VCards data={entity.vcardArray} />
                  <Box>
                    <Entry label={'Roles:'} value={
                      <Stack direction={'row'} spacing={2} sx={{ ml: 1 }}>
                        {deduplicateArray(entity.roles).map((s) => (
                          <Chip 
                            key={s} 
                            label={s} 
                            size='small' 
                            sx={{ ml: 1, backgroundColor: colors.lightGreen, color: colors.white }} 
                          />
                        ))}
                      </Stack>
                    }  key={`status-${index}`} />
                  </Box>
                  <Remarks data={entity.remarks} />
                  <PublicIds data={entity.publicIds} />
                </Stack>
              </CoccaAccordionDetails>
            </CoccaAccordion>
          ))}
        </CoccaAccordionDetails>
      </CoccaAccordion>
    </Card>
  );
};

export default function CustomizedAccordions({ data, expandAll }) {
  const [expanded, setExpanded] = React.useState(data?.map(d => expandAll));

  const handleChange = (panel) => (event, isExpanded) => {
    const newExpanded = [...expanded];

    newExpanded[panel] = isExpanded ? true : false;

    setExpanded(newExpanded);
  };

  if (!data) {
    return null;
  }

  return (
    <AccordionWrapper title={ data.length == 1 ? 'Entity' : 'Entities' }>
      {data.map((entity, index) => (
        <CoccaAccordion expanded={expanded[index]} onChange={handleChange(index)} key={`outer-accordion-${entity.handle}-${index}`}>
          <CoccaAccordionSummary sx={{ backgroundColor: colors.lightGrey }} className='rdap-entities-summary'>
            <Typography>{entity.handle} ({deduplicateArrayToString(entity.roles)})</Typography>
          </CoccaAccordionSummary>
          <CoccaAccordionDetails className='rdap-entities-details'>
            <Stack>
              <Entry label={'Object class name:'} value={entity.objectClassName} />
              <Entry label={'Handle:'} value={entity.handle} />
              <VCards data={entity.vcardArray} />
              <Box>
                <Entry label={'Roles:'} value={
                  <Stack direction={'row'} spacing={2} sx={{ ml: 1 }}>
                    {deduplicateArray(entity.roles).map((s) => (
                      <Chip 
                        key={s} 
                        label={s} 
                        size='small' 
                        sx={{ ml: 1, backgroundColor: colors.lightGreen, color: colors.white }} 
                      />
                    ))}
                  </Stack>
                }  key={`status-${index}`} />
              </Box>
              <Remarks data={entity.remarks} />
              <PublicIds data={entity.publicIds} />
              <NestedEntities data={entity.entities} />
            </Stack>
          </CoccaAccordionDetails>
        </CoccaAccordion>
      ))}
    </AccordionWrapper>
  );
}
